<template>
  <div>
    <div class="py-40 center" v-if="boxList.length === 0">
      <p>Please wait ...</p>
      <b-spinner type="grow"></b-spinner>
    </div>
    <b-row class="mx-5 mt-10 mb-30" v-else>
      <b-col cols="12" md="4" v-for="(item, index) in boxList" :key="(item, index)">
        <div class="mt-5 shadow p-5 bg-white rounded center d-flex flex-column">
          <img :src="item.photoUrl" :width="200" :height="200" />
          <br/>
          <span class="font-bold font-20">{{ item.name }}</span>
          <span class="font-15">{{ item.description }}</span>
          <br/>
          <span class="box-detail">{{ item.detail }}</span>
          <span class="font-bold font-20 font-pink">{{ getPriceFormat(Number.parseInt(item.price) / 100, true) }}</span>
          <v-btn class="mt-5 text-capitalize" color="#EB4C60" @click="gotoBoxDetail(item.boxId)" block large>
            <span class="font-bold text-white font-15">Buy Now</span>
          </v-btn>
        </div>
      </b-col>
    </b-row>
    <div class="h-100px" v-if="!$isMobile()" />
    <!-- Footer -->
    <KTFooterExtended></KTFooterExtended>
  </div>
</template>

<style lang="scss" scoped>
  .box-detail {
    display: -webkit-box;
    font-size: 15px;
    min-height: 200px;
    -webkit-line-clamp: 9;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
</style>

<script>
import KTFooterExtended from "@/view/layout/footer/FooterExtended.vue";

import { getPriceFormat } from '../../../functions';

export default {
  name: "BoxList",
  components: {
    KTFooterExtended
  },
  computed: {
    boxList() {
      return this.$store.state.boxList;
    }
  },
  methods: {
    getPriceFormat,
    gotoBoxDetail(id) {
      this.$router.push({path: `/box/${id}`});
    }
  }
};
</script>